import React, { Component } from "react";
import { connect } from "react-redux";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Typography from "@material-ui/core/Typography";
import { sendMessage } from "../../actions/user";
import { toaster } from "../../helper/Toaster";

import {
  ToastsContainer,
  ToastsStore,
  ToastsContainerPosition,
} from "react-toasts";
import SendNotification from "./SendNotification";

export class Notification extends Component {
  constructor(props) {
    super(props);

    this.state = {
      notification_data: {
        title: "",
        message: "",
        selectUser: "",
        selectMultipleUser: "",
      },
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { notification_data } = newProps;

    if (notification_data && notification_data.status === 200) {
      this.setState({
        notification_data: notification_data.data,
      });
    } else if (notification_data && notification_data.status === 500) {
      toaster("error", notification_data.message);
    }
  }

  handleSendNotify = (e, title) => {
    var notification_data = this.state.notification_data;
    if (title === "selectMultipleUser") {
      notification_data[title] = e;
    } else {
      notification_data[title] = e.target.value;
    }
    this.setState({ notification_data });
  };

  submitData = (e) => {
    const { notification_data } = this.state;
    const { title, message, selectUser, selectMultipleUser } =
      notification_data;

    if (title === "" || message === "" || selectUser === "") {
      toaster("error", "Please select all the fields");
    } else {
      if (selectUser === "selectedUser") {
        if (selectMultipleUser.length === 0) {
          toaster("error", "Please select all the fields");
          return;
        }
      }
      let params = {
        title: title,
        message: message,
        selectUser: selectUser,
        selectMultipleUser: selectMultipleUser,
      };
      this.props.sendMessage(params, "user");
      toaster("success", "Notification sending process initiated!");
      window.location.reload();
    }
  };

  render() {
    return (
      <GridContainer>
        <ToastsContainer
          store={ToastsStore}
          position={ToastsContainerPosition.TOP_RIGHT}
        />
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <Typography variant="h5" className="header_platform">
                Send Notification
              </Typography>
            </CardHeader>

            <CardBody>
              <SendNotification
                state={this.state}
                handleSendNotify={this.handleSendNotify}
                submitData={this.submitData}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}
const mapStateToProps = (store) => {
  return {
    notification_data: store.user.notification_data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendMessage: (params, role) => dispatch(sendMessage(params, role)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
